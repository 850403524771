import React from "react";
import {Box, Flex, Heading} from "@chakra-ui/react";


export const HeaderSection = ({text,headerAlign, flexGrowRight,flexGrowLeft}) => {

    const heightBar ='10px'
    return <Flex sx={{alignItems:'center',p:'4px', mt:'16px',lineHeight:1.75}}>
            <Box sx={{flexGrow:flexGrowLeft?flexGrowLeft:1,height:heightBar,opacity:0.3,bg:'border'}}></Box>
            <Box sx={{fontFamily:'heading',fontWeight:'bold', p:'4px',mb:0,color:'text.100',height:"40px"}}>{text}</Box>
            <Box sx={{flexGrow:flexGrowRight?flexGrowRight:1,height:heightBar,opacity:0.3,bg:'border'}}></Box>
        </Flex>
}


export const HeaderSectionBig = ({text,idName}) => {
    return <Box sx={{pt:[6,"40px"]}}><Heading
        id={idName?idName:""}
        sx={{position:'relative',zIndex:0,mt:0,mb:1,ml:1,color:'text.300'}}
                   fontWeight={"bold"} letterSpacing={2} fontSize={"23px"}
                    _before={{content: '""',bg:'primary.100',zIndex:-1,
  display: 'inline-block', width: '15px',height:'100%',ml:'-5px',position:'absolute'}} >{text}</Heading>
        </Box>
}



export const HeaderSectionBigMain = ({text,idName}) => {
    const heightBar ='1px'
    return <Flex id={idName} sx={{alignItems:'center',p:'4px', pt:'25px',lineHeight:1.25}}>
            <Box sx={{flexGrow:1,height:heightBar,opacity:0.3,bg:'border'}}></Box>
            <Heading  sx={{fontFamily:'heading',fontWeight:'bold', p:'4px',mb:'15px',color:'text.300',height:"40px",maxWidth:'calc(100% - 15px)'}}>{text}</Heading>
            <Box sx={{flexGrow:1,height:heightBar,opacity:0.3,bg:'border'}}></Box>
    </Flex>
}